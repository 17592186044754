<template>
    <div>
        <br>
        <br>
        <a-row v-if="errMsg" type="flex" justify="center">
            <a-col style="color: #f81d22;">{{ errMsg }}</a-col>
        </a-row>
        <a-row v-else type="flex" justify="center">
            <a-col flex="30px"><a-spin /></a-col>
            <a-col flex="130px">正在登录，请稍后...</a-col>
        </a-row>
    </div>
</template>
  
<script>
import { authorize } from "@/api/user";
import Cookies from "js-cookie";

export default {
    data() {
        return {
            errMsg: "",
        };
    },
    mounted() {
        authorize({ code: this.$functions.getUrlKey("code") })
            .then((data) => {
                if (data.err_msg) {
                    this.errMsg = `错误：${data.err_msg}`;
                } else {
                    Cookies.set("access", data.access);
                    Cookies.set("refresh", data.refresh);
                    this.$router.push("/home");
                }
            });
    },
};
</script>
  